import request from '@/utils/request'
const baseURL = '/jt-api'

// 查询报警规则列表
export function listWarningRule(query) {
  return request({
    url: '/jt808/warningRule/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 新增报警规则
export function addWarningRule(data) {
  return request({
    url: '/jt808/warningRule',
    method: 'post',
    baseURL,
    data: data
  })
}

// 修改报警规则
export function updateWarningRule(data) {
  return request({
    url: '/jt808/warningRule',
    method: 'put',
    baseURL,
    data: data
  })
}

// 获取报警规则
export function getWarningRule(id) {
  return request({
    url: '/jt808/warningRule/' + id,
    baseURL,
    method: 'get'
  })
}

// 删除报警规则
export function delWarningRule(id) {
  return request({
    url: '/jt808/warningRule/' + id,
    baseURL,
    method: 'delete'
  })
}
